import React, { useContext, useEffect } from 'react';
import {
  Chip,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { findOnArray, summarizer, getDatePagto } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { useModal } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';
import ConfirmaçãoModal from './Modals/ConfirmaçãoModal';

const Fechamento = () => {
  const defaultValues = {
    observacao: '',
    // ITENS
    itens: [],
    // PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    pagamento: [],
  };
  const navigate = useNavigate();
  const { id } = useParams();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading, getDocumento, getLoading } =
    useContext(DocumentosContext);
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (Boolean(id)) {
      getDocumento({
        id,
        cb: (data) => {
          setValue('observacao', data?.observacao || '');
          setValue(
            'itens',
            data?.DocumentoItems?.map((item) => ({
              id: item?.id,
              documento_id: item?.documento_id,
              descricao: item?.descricao,
              quantidade: item?.quantidade,
              quantidade_max: item?.quantidade,
              preco: item?.preco,
              unidade_medida: item?.UnidadeMedida?.sigla,
              subtotal: (item?.quantidade || 0) * (item?.preco || 0),
              saldo: item?.saldo,
            })) || []
          );
        },
      });
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    handleSubmit(onRecalc)();
  }, [watch('itens')]);

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const condicoes =
        findOnArray(forma_pagto_id, drops?.FormaDePagamento, 'condicoes') || [];
      const cond_pagto = findOnArray(condicao_pagamento_id, condicoes);
      const valor_total = summarizer(values?.itens, 'subtotal');

      if (cond_pagto && valor_total > 0) {
        const pagamento = [];
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            parcela,
            dtvenc: getDatePagto({ ...cond_pagto, parcela, fixar }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
        setValue('pagamento', pagamento);
      } else {
        setValue('pagamento', []);
      }
    }
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onCellEditCommit = (e) => {
    const itens = watch('itens')?.map((c) => {
      if (c?.id === e?.id && e?.field === 'quantidade') {
        let subtotal = 0;
        if (e?.value > c?.quantidade_max) {
          subtotal = (c?.quantidade_max || 0) * (c?.preco || 0);
          return { ...c, [e?.field]: c?.quantidade_max, subtotal };
        } else {
          subtotal = (e?.value || 0) * (c?.preco || 0);
          return { ...c, [e?.field]: e?.value, subtotal };
        }
      } else {
        return { ...c };
      }
    });
    setValue('itens', itens);
  };

  const onSubmit = (values) => {
    const not_available = values?.itens?.some((s) => s?.quantidade > s?.saldo);
    if (not_available) {
      return toastWarning(
        'Não é possível finalizar pedido. Itens indisponíveis'
      );
    }
    if (!Boolean(values?.pagamento?.length)) {
      return toastWarning(
        'Não é possível finalizar pedido. Pagamento não informado'
      );
    }
    const data = {
      Documento: {
        id,
        especie_id: 9,
        documento: null,
        observacao: values?.observacao,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      Saida: {
        condicao_pagamento_id: values?.condicao_pagamento_id,
        ped_cliente: 'HMC PORTAL',
      },
      Itens: values?.itens?.map((item) => ({
        id: item?.id,
        quantidade: item?.quantidade,
      })),
      Financeiros: values?.pagamento,
      Registros: [{ status_id: 1 }],
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  const renderCell = ({ row }) => {
    if (row?.saldo <= 0) {
      return (
        <Chip
          label="Indisponível"
          variant="outlined"
          size="small"
          color="secondary"
        />
      );
    } else if (row?.quantidade > row?.saldo) {
      let label = `${row?.saldo} ${row?.unidade_medida} ${
        row?.saldo === 1 ? 'Disponível' : 'Disponíveis'
      }`;
      return (
        <Chip label={label} variant="outlined" size="small" color="secondary" />
      );
    } else {
      return (
        <Chip
          label="Disponível"
          variant="outlined"
          size="small"
          color="success"
        />
      );
    }
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Fechamento do Pedido" />
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              rows={watch('itens')}
              columns={[
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 5,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  flex: 1,
                  editable: true,
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade || 0} ${row?.unidade_medida || ''}`,
                },
                {
                  field: 'preco',
                  headerName: 'Preço Unitário',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
                {
                  field: 'subtotal',
                  headerName: 'Subtotal',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
                {
                  field: 'disponibilidade',
                  headerName: 'Disponibilidade',
                  type: 'boolean',
                  flex: 1,
                  sortable: false,
                  renderCell,
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  flex: 1,
                  getActions: ({ id }) => [
                    <GridActionsCellItem
                      icon={<Icon>delete</Icon>}
                      label="Excluir"
                      onClick={() =>
                        setValue(
                          'itens',
                          watch('itens')?.filter((r) => r?.id !== id)
                        )
                      }
                    />,
                  ],
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={drops?.FormaDePagamento?.filter(
                      (f) =>
                        f?.modulo !== 'COMPRAS' &&
                        f?.modulo !== 'CHEQUES' &&
                        f?.caixa === 'SIM'
                    )}
                    onValueChange={() =>
                      setValue('condicao_pagamento_id', null)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="condicao_pagamento_id"
                    control={control}
                    label="Condição de Pagamento"
                    options={findOnArray(
                      watch('forma_pagto_id'),
                      drops?.FormaDePagamento,
                      'condicoes'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name="fixar" control={control} label="Fixar Dia" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'label'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Informações Adicionais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            openModal(
              <ConfirmaçãoModal onSubmit={handleSubmit(onSubmit)} />,
              30
            )
          }
          loading={postLoading}
        >
          Finalizar
        </Button>
      </Grid>
    </Container>
  );
};

export default Fechamento;
