import React, { useContext, useEffect } from 'react';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { summarizer } from 'utils/functions';
import { PedidosContext } from 'contexts/PedidosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment/moment';

const Pedidos = () => {
  const titulo = 'Meus Pedidos';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { getLoading, getPedidos, pedidos } = useContext(PedidosContext);
  const { deleteDocumento } = useContext(DocumentosContext);

  useEffect(() => {
    getPedidos();
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  const getStatus = (ped) => {
    if (ped?.situacao === 'DIGITAÇÃO') {
      return { label: 'EM DIGITAÇÃO', color: 'info' };
    } else if (ped?.status === 'CANCELADO') {
      return { label: 'CANCELADO', color: 'error' };
    } else if (ped?.status === 'CONCLUIDO') {
      return { label: 'CONCLUIDO', color: 'success' };
    } else if (ped?.status === 'PENDENTE') {
      const label = ped?.situacao?.replace('DOCUMENTO ', '');
      return { label, color: 'warning' };
    }
    return null;
  };

  const onDelete = (id) =>
    deleteDocumento({
      id,
      cb: () => {
        getPedidos();
        closeModal();
      },
    });

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/app/Pedidos/Manutencao')}
          >
            NOVO PEDIDO
          </Button>
        </Grid>
        {pedidos?.map((p) => (
          <Grid
            key={p?.id?.toString()}
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}
            display="flex"
          >
            <Card style={styles?.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" color="primary">
                  {p?.documento}
                </Typography>
                <Typography variant="h6" color="primary">
                  {moment(p?.dtemissao).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Box sx={styles?.boxContainer}>
                <Typography variant="subtitle2">ITENS</Typography>
                {p?.DocumentoItems?.map((i) => (
                  <Box key={i?.id?.toString()} sx={styles?.lineContainer}>
                    <Typography variant="body2" fontSize={12} alignSelf="end">
                      {i?.descricao}
                    </Typography>
                    <Box sx={styles?.dots} />
                    <Typography variant="body2" fontSize={12} alignSelf="end">
                      {i?.quantidade} {i?.unidade_medida || ''}
                    </Typography>
                    <Box sx={styles?.dots} />
                    <Typography variant="body2" fontSize={12}>
                      {(i?.preco || 0)?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                    <Box sx={styles?.dots} />
                    <Typography variant="body2" fontSize={12}>
                      {((i?.preco || 0) * (i?.quantidade || 0))?.toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </Typography>
                  </Box>
                ))}
                <Box sx={styles?.lineContainer}>
                  <Typography variant="body2" fontSize={12} alignSelf="end">
                    TOTAL
                  </Typography>
                  <Box sx={styles?.dots} />
                  <Typography variant="body2" fontSize={12}>
                    {summarizer(
                      p?.DocumentoItems?.map((s) => ({
                        subtotal: (s?.preco || 0) * (s?.quantidade || 0),
                      })),
                      'subtotal'
                    )?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles?.boxContainer}>
                <Typography variant="subtitle2">PAGAMENTOS</Typography>
                {p?.DocumentoFinanceiros?.map((p, i) => (
                  <Box key={i?.toString()} sx={styles?.lineContainer}>
                    <Typography variant="body2" fontSize={12}>
                      {p?.parcela}ª Parcela:{' '}
                      {p?.valor?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}{' '}
                      (Vencimento: {moment(p?.dtvenc).format('DD/MM/YYYY')})
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box sx={styles?.chipContainer}>
                <Chip size="small" {...getStatus(p)} />
                {p?.situacao === 'DIGITAÇÃO' && (
                  <Box>
                    <Tooltip title="Editar Pedido">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() =>
                          navigate(`/app/Pedidos/Manutencao/${p?.id}`)
                        }
                      >
                        <Edit fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir Pedido">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() =>
                          openModal(
                            <DeleteModal onSubmit={() => onDelete(p?.id)} />
                          )
                        }
                      >
                        <Delete fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Finalizar Pedido">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() =>
                          navigate(`/app/Pedidos/Fechamento/${p?.id}`)
                        }
                      >
                        <Check fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Pedidos;
