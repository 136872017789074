import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { checkInfosProduto, summarizer } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog, useModal } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import ItemModal from './Modals/ItemModal';
import styles from './styles';
import ConfirmaçãoModal from './Modals/ConfirmaçãoModal';
import { GridContext } from 'contexts/GridContext';

const Manutenção = () => {
  const defaultValues = {
    observacao: '',
    //ITENS
    produto_id: null,
    quantidade: '',
    infadc: '',
    itens: [],
  };
  const detailsProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const textDetailsProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  };
  const navigate = useNavigate();
  const [additionals, setAdditionals] = useState(null);
  const [loading, setLoading] = useState(false);
  const { openDialog } = useDialog();
  const { openModal } = useModal();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const { postDocumento, postLoading, getDocumento, getLoading } =
    useContext(DocumentosContext);
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (Boolean(id)) {
      getDocumento({
        id,
        cb: (data) => {
          setValue('observacao', data?.observacao || '');
          setValue(
            'itens',
            data?.DocumentoItems?.map((di) => ({
              id: di?.id,
              preco: di?.preco,
              infadc: di?.infadc,
              descricao: di?.descricao,
              produto_id: di?.produto_id,
              quantidade: di?.quantidade,
              unidade_medida_id: di?.unidade_medida_id,
              subtotal: (di?.quantidade || 0) * (di?.preco || 0),
            }))
          );
        },
      });
    }
  }, []);

  useEffect(() => {
    if (Boolean(watch('produto_id'))) {
      getAdditionals();
    } else {
      setAdditionals(null);
    }
  }, [watch('produto_id')]);

  const getAdditionals = async () => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id: watch('produto_id'),
      disponivel: 'SIM',
      tipo_saldo: '',
      vendedor_id: 1,
    });
    setAdditionals(produto);
    setValue('quantidade', produto?.quantidade || 1);
    setLoading(false);
  };

  const onAddItem = (values) => {
    const subtotal = (values?.quantidade || 0) * (additionals?.preco || 0);
    setValue('itens', [
      ...values?.itens,
      {
        subtotal,
        preco: additionals?.preco,
        descricao: additionals?.descricao,
        unidade_medida_id: additionals?.unidade_medida_id,
        infadc: values?.infadc,
        produto_id: values?.produto_id,
        quantidade: values?.quantidade,
      },
    ]);
    resetField('produto_id');
    resetField('quantidade');
    resetField('infadc');
  };

  const onDeleteItem = (item, index) => {
    const cb = () =>
      setValue(
        'itens',
        watch('itens')?.filter((_, i) => i !== index)
      );
    if (Boolean(item?.id)) {
      deleteGrid({ params: { rotina: 'DocumentoItem', id: item?.id }, cb });
    } else {
      cb();
    }
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        id,
        especie_id: 8,
        natureza_operacao_id: 6,
        filial_id: 1,
        users_id: user?.id,
        cadastro_id: user?.entidade_id,
        observacao: values?.observacao,
      },
      Itens: values?.itens?.map((i, index) => ({
        id: i?.id,
        sequencia: index + 1,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        quantidade: i?.quantidade,
        unidade_medida_id: i?.unidade_medida_id,
        preco: i?.preco,
        infadc: i?.infadc,
      })),
      Saida: {
        vendedor_id: 1,
      },
      Registros: !Boolean(id) ? [{ status_id: 30 }] : [],
    };

    postDocumento({
      data,
      cb: ({ id }) =>
        openModal(
          <ConfirmaçãoModal
            onBack={() => navigate(-1)}
            onSubmit={() =>
              navigate(`/app/Pedidos/Fechamento/${id}`, { replace: true })
            }
          />
        ),
    });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={(Boolean(id) ? 'Alterar' : 'Novo') + ' Pedido'} />
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="subtitle2" color="secondary">
              PREÇOS E QUANTIDADES SUJEITOS À ALTERAÇÕES ATÉ A DATA DE
              CONFIRMAÇÃO/ENVIO DO PEDIDO
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VirtualDrop
                    name="produto_id"
                    control={control}
                    label="Produto"
                    options={drops?.Produto}
                    refresh="Produto"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="infadc"
                    control={control}
                    label="Informações Adicionais"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    {Boolean(additionals) && (
                      <Typography variant="body1">
                        Preço Unitário:{' '}
                        {additionals?.preco?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    )}
                    {Boolean(additionals) && (
                      <Chip
                        size="small"
                        label={
                          Boolean(additionals?.saldo > 0)
                            ? 'DISPONÍVEL'
                            : 'INDISPONÍVEL'
                        }
                        color={
                          Boolean(additionals?.saldo > 0) ? 'success' : 'error'
                        }
                      />
                    )}
                    <Typography variant="h6" align="right" color="primary">
                      {(
                        (additionals?.preco || 0) * (watch('quantidade') || 0)
                      )?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Preço Unitário</TableCell>
                          <TableCell>Sub Total</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>
                              {item?.preco?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.subtotal?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      item={item}
                                      index={index}
                                      itens={watch('itens')}
                                      setItens={(i) => setValue('itens', i)}
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(item, index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Informações Adicionais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Manutenção;
