import { createContext, useState } from 'react';
import api from 'services/api';

export const DocumentosContext = createContext();

export const DocumentosProvider = ({ children }) => {
  const [documento, setDocumento] = useState(null);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getDocumento = async ({ id, cb }) => {
    try {
      setGetLoading(true);
      setDocumento(null);
      const { data } = await api.get(`/Cadastros/Documento/${id}`, {
        params: { Itens: true, Financeiro: true },
      });
      setDocumento(data);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postDocumento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const { data: response } = await api.post('/Cadastros/Documento', data);
      if (cb) {
        cb(response);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteDocumento = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Documento/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <DocumentosContext.Provider
      value={{
        documento,
        getLoading,
        postLoading,
        deleteLoading,
        getDocumento,
        postDocumento,
        deleteDocumento,
      }}
    >
      {children}
    </DocumentosContext.Provider>
  );
};
