import { createContext, useState } from 'react';
import api from 'services/api';

export const PedidosContext = createContext();

export const PedidosProvider = ({ children }) => {
  const [pedidos, setPedidos] = useState([]);
  const [getLoading, setGetLoading] = useState(false);

  const getPedidos = async () => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Comercial/Pedidos');
      setPedidos(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <PedidosContext.Provider
      value={{
        pedidos,
        getLoading,
        getPedidos,
      }}
    >
      {children}
    </PedidosContext.Provider>
  );
};
