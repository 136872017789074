import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
import Pedidos from 'views/Pedidos';
import PedidosManutenção from 'views/Pedidos/Manutenção';
import PedidosFechamento from 'views/Pedidos/Fechamento';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Pedidos" element={<Outlet />}>
      <Route path="" element={<Pedidos />} />
      <Route path="Manutencao" element={<PedidosManutenção />} />
      <Route path="Manutencao/:id" element={<PedidosManutenção />} />
      <Route path="Fechamento/:id" element={<PedidosFechamento />} />
    </Route>
  </Routes>
);

export default RoutesTree;
