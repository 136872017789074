import React from 'react';
import { Box, DialogContent, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useModal } from 'components/Modals';
import Button from 'components/Button';

const ConfirmaçãoModal = ({ onSubmit, onBack }) => {
  const { closeModal } = useModal();

  return (
    <DialogContent sx={{ textAlign: 'center' }}>
      <InfoOutlined color="primary" sx={{ fontSize: 150 }} />
      <Typography variant="h6" sx={{ mb: '.5em' }}>
        Seu pedido foi salvo
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-evenly">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onBack();
            closeModal();
          }}
        >
          Voltar ao início
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onSubmit();
            closeModal();
          }}
        >
          Concluir Pedido
        </Button>
      </Box>
    </DialogContent>
  );
};

export default ConfirmaçãoModal;
