import React from 'react';
import { DialogContent, DialogActions, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useModal } from 'components/Modals';
import Button from 'components/Button';

const ConfirmaçãoModal = ({ onSubmit }) => {
  const { closeModal } = useModal();

  return (
    <>
      <DialogContent sx={{ textAlign: 'center' }}>
        <InfoOutlined color="primary" sx={{ fontSize: 150 }} />
        <Typography variant="h6" sx={{ my: '.5em' }}>
          Confirmação do Pedido
        </Typography>
        <Typography variant="body1">
          Ao confirmar, seu pedido não poderá ser desfeito e nem alterado.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSubmit();
            closeModal();
          }}
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmaçãoModal;
