const styles = {
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxContainer: {
    flex: 1,
    margin: '.5em 0',
  },
  lineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    mt: '.25em',
  },
  dots: {
    borderBottom: '1px dotted',
    flexGrow: 1,
    mx: '.25em',
  },
};

export default styles;
