import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Input from 'components/Input';

const ItemModal = ({ item, index, setItens, itens }) => {
  const { closeDialog } = useDialog();

  const defaultValues = {
    quantidade: item?.quantidade || '',
    infadc: item?.infadc || '',
  };
  const { control, watch, handleSubmit } = useForm({ defaultValues });

  const onSubmit = (values) => {
    const subtotal = (watch('quantidade') || 0) * (item?.preco || 0);
    setItens(
      itens?.map((i, idx) =>
        index === idx ? { ...i, ...values, subtotal } : i
      )
    );
    closeDialog();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {item?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="infadc"
          control={control}
          label="Informações Adicionais"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ItemModal;
