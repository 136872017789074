import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { DocumentosProvider } from 'contexts/DocumentosContext';
import { PedidosProvider } from 'contexts/PedidosContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <DocumentosProvider>
            <PedidosProvider>
              <ModalProvider>
                <DialogProvider>
                  <DrawerProvider>{children}</DrawerProvider>
                </DialogProvider>
              </ModalProvider>
            </PedidosProvider>
          </DocumentosProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
