import moment from 'moment';
import api from 'services/api';

export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const summarizer = (arr = [], field) =>
  arr?.length &&
  Number(
    arr
      ?.map((i) => i[field])
      ?.reduce((prev, next) => prev + next)
      ?.toFixed(2)
  );

export const checkInfosProduto = async ({ produto_id, ...rest }) => {
  const { data } = await api.get(`/Cadastros/Produto/${produto_id}`, {
    params: { ...rest, Midias: true, Descricao: true },
  });
  const imagem = data?.ProdutoImagems?.find((f) => f?.Midium?.tipo === 'IMAGEM')
    ?.Midium?.url;
  const descricao = data?.ProdutoDescricaos?.find(
    (f) => f?.tipo === 'COMPLETA' || f?.tipo === 'REDUZIDA'
  )?.descricao;
  return { ...data, imagem, descricao };
};

export const getDatePagto = (values) => {
  let start_date = moment(values?.start_date).format('YYYY-MM-DD');
  if (values?.fora === 'SEMANA') {
    start_date = moment().add(1, 'week').format('YYYY-MM-DD');
  }
  if (values?.fora === 'QUINZENA') {
    start_date = moment().add(15, 'days').format('YYYY-MM-DD');
  }
  if (values?.fora === 'MES') {
    start_date = moment().add(1, 'month').format('YYYY-MM-DD');
  }

  if (Boolean(values?.intervalop >= 0)) {
    start_date = moment(start_date)
      .add(values?.intervalop, 'days')
      .format('YYYY-MM-DD');
    if (values?.parcela !== 1) {
      start_date = moment(start_date)
        .add(values?.intervalo * (values?.parcela - 1), 'days')
        .format('YYYY-MM-DD');
    }
  } else {
    start_date = moment(start_date)
      .add(values?.intervalo * values?.parcela, 'days')
      .format('YYYY-MM-DD');
  }

  if (values?.fixar && values?.intervalo % 30 === 0) {
    const diaFixo = moment(values?.start_date).format('DD');
    start_date = moment(start_date).set('D', diaFixo).format('YYYY-MM-DD');
  }

  if (Boolean(values?.dia)) {
    while (Number(moment(start_date).format('DD')) !== values?.dia) {
      start_date = moment(start_date).add(1, 'day').format('YYYY-MM-DD');
    }
  }
  return start_date;
};
